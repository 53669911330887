














import {Component, Vue} from "vue-property-decorator";
import RouteNames from "@/router/RouteNames";
import {namespace} from "vuex-class";

const AppModule = namespace("App");

@Component
export default class IndividualPayrollForms extends Vue {

  @AppModule.State
  private isMobile!: boolean;

  goToW2(){
    this.$router.push({name: RouteNames.PAYROLL_INDIVIDUAL_FORMS_W2, params: {personId: this.$route.params.personId}});
  }

  goTo1099(){
    this.$router.push({name: RouteNames.PAYROLL_INDIVIDUAL_FORMS_1099, params: {personId: this.$route.params.personId}});
  }

}
